<template>
  <div>
    <search-header :currentActive="8"></search-header>

    <div class="container p-0 mt-4">
      <div v-if="displayList.length == 2" class="row">
        <div class="col-4" v-for="m in displayList" :key="m.id">
          <div class="border-bottom pb-2 mb-3">
            <span class="h5">{{m.text}}</span>
          </div>
          <div v-if="m.text == '热门图文'">
            <router-link :to="'/news/detail?id=' + m.thumbList[0].id" target="_blank">
              <img class="mw-100" :src="m.thumbList[0].thumbnail" :title="m.thumbList[0].title"/>
            </router-link>
          </div>
          <div v-else>
             <div class="row">
              <div class="col-4 height-70">
                <router-link :to="'/news/detail?id=' + m.thumbList[0].id" target="_blank">
                  <img class="w-100" :src="m.thumbList[0].thumbnail" style="height:70px"/>
                </router-link>
              </div>
              <div class="col-8 p-0">
                <router-link :to="'/news/detail?id=' + m.thumbList[0].id" target="_blank">
                  <div class="text-truncate width-250">{{m.thumbList[0].title}}</div>
                </router-link>
                <div class="text-secondary width-250 ellipsis-2" v-html="m.thumbList[0].content"></div>
              </div>
            </div>
            <div class="text-truncate" :class="{ 'mt-3' : j == 0,  'mt-2' : j > 0}" v-for="s,j in displayList[1].list" :key="s.id"><router-link :to="'/news/detail?id=' + s.id" target="_blank">{{s.title}}</router-link></div>
          </div>
        </div>
        <div class="col-4">
          <tool-right></tool-right>
        </div>
      </div>
    </div>

    <div class="container p-0 mt-4">
      <div class="border-bottom pb-2 mb-2">
        <span class="h5">推荐土地</span>
      </div>
      <div class="row my-4">
        <div class="col-2" v-for="(m,i) in tjLandList" :key="i">
          <land-right :datas="m" :height="150"></land-right>
        </div>
      </div>
    </div>


    <div v-for="m,i in list" :key="i" class="container p-0  my-4">
      <div class="border-bottom pb-2 mb-3">
        <span class="h5">{{m.text}}</span>
      </div>
      <div class="row">
        <div class="col-4">
          <div v-for="a in m.list.slice(0,7)" :key="a.id" class="row mb-1">
            <div>
              <svg class="bi bi-circle-fill color-fz" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="3"/>
              </svg>
            </div>
             <router-link :to="'/news/detail?id=' + a.id" target="_blank" class="text-truncate col p-0">{{a.title}}</router-link>
          </div>
        </div>
        <div class="col-4">
          <div v-for="a in m.list.slice(7,14)" :key="a.id" class="row mb-1">
            <div class="col-1">
              <svg class="bi bi-circle-fill color-fz" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="3"/>
              </svg>
            </div>
            <router-link :to="'/news/detail?id=' + a.id" target="_blank" class="text-truncate col p-0">{{a.title}}</router-link>
          </div>
        </div>
        <div class="col-4">
          <div class="row  mb-3">
            <div class="col-4 height-70">
              <router-link :to="'/news/detail?id=' + m.thumbList[0].id" target="_blank">
                <img class="w-100" :src="m.thumbList[0].thumbnail" style="height:70px"/>
              </router-link>
            </div>
            <div class="col-8 p-0">
              <div class="text-truncate width-250"> <router-link :to="'/news/detail?id=' + m.thumbList[0].id" target="_blank">{{m.thumbList[0].title}}</router-link></div>
              <div class="text-secondary width-250 ellipsis-2" v-html="m.thumbList[0].content"></div>
            </div>
          </div>
          <div v-for="a in m.list.slice(14,18)" :key="a.id" class="row mb-1">
            <div class="col-1">
              <svg class="bi bi-circle-fill color-fz" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="3"/>
              </svg>
            </div>
            <router-link :to="'/news/detail?id=' + a.id" target="_blank" class="text-truncate col p-0">{{a.title}}</router-link>
          </div>
        </div>
      </div>
    </div>

    <map-footer></map-footer>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import toolRight from "@/components/right/tool-right.vue";
import landRight from "@/components/right/land-right.vue";
export default {
  data() {
    return {
      list: [],
      displayList:[],
      tjLandList: [],
      column: ['土地快讯','国家政策','土地百科'],
    }
  },
  components: {
    toolRight,landRight
  },
  computed: {
    ...mapGetters(['dict']),
  },
  watch: {
    'dict.newsDisplay': 'init'
  },
  methods: {
    getLandList() {
      this.$http.post(this.$api.supply.getNeedsForLetList, {
          param: {}, 
          size: 6,
          current: 2 
      }).then((res) => {
          this.tjLandList = res.result.records.map(x=> {
              let f = x.userNeedsFiles.find(y=> y.uploadType == 1);
              return {
                  id: x.id,
                  fileUrl: f ? f.fileUrl : '',
                  title: x.title,
                  price: x.price,
                  priceUnit: x.priceUnit,
                  measureArea: x.measureArea,
                  acreageUnit: x.acreageUnit,
              }
          });
      });
    },
    init() {
      if(this.dict) {
        if(this.dict.newsDisplay.length > 0) {
          this.getDisplayList();
        }
        if(this.dict.newsClass.length >0) {
          let c = this.dict.newsClass.filter(x=> this.column.includes(x.text));
          if(c) {
            let _this = this;
            c.forEach(x => {
              _this.getNewsColumn(x);
            });
          }
        }
      }
    },
    async getDisplayList() {
        let d = this.dict.newsDisplay.find(x=> x.text == '热门图文');
        await this.$http.post(this.$api.news.getList, { param: { showMode: d.id}, size: 1, current: 1})
        .then(res => {
            d.thumbList = res.result.records;
        })
        this.displayList.push(d);

        let d2 = this.dict.newsDisplay.find(x=> x.text == '每周精品');
        await this.$http.post(this.$api.news.getList, { param: { showMode: d2.id, isThumbnail: 1}, size: 1, current: 1})
        .then(res => {
            d2.thumbList = res.result.records.map(x=> {
            return {
              title: x.title,
              content: x.content.replace(/<[^>]*>/g,''),
              id: x.id,
              thumbnail: x.thumbnail
            }
          });
        })

        await this.$http.post(this.$api.news.getList, { param: { showMode: d2.id, isThumbnail: 0}, size: 5, current: 1})
        .then(res => {
            d2.list = res.result.records;
        })
        this.displayList.push(d2);
    },
    async getNewsColumn(m) {
      let model = m;
      await this.$http.post(this.$api.news.getList, { param: { contentType: m.id},sortBy:{browseNum:'DESC'}, size: 18, current: 1})
      .then(res => {
        m.list = res.result.records;
      })
      
      await this.$http.post(this.$api.news.getList, { param: { contentType: m.id, isThumbnail: 1 },sortBy:{browseNum:'DESC'}, size: 1, current: 1})
      .then(res => {
        m.thumbList = res.result.records.map(x=> {
          return {
            title: x.title,
            content: x.content.replace(/<[^>]*>/g,''),
            id: x.id,
            thumbnail: x.thumbnail
          }
        });
      })
      this.list.push(m);
    }
  },
  created() {
    this.init();
    this.getLandList();
  }
}
</script>

<style scoped>

</style>
